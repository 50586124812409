<template>
  <div class="styles-to-choose-content" v-if="stylesToChoose">
    <div class="title">选择风格，有助于给您推荐最合适人选</div>
    <div class="selects">
      <div
        class="button"
        :class="item.checked ? 'buttonChecked' : ''"
        v-for="item in selects"
        :key="item.id"
        @click="item.checked = !item.checked"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="submit">
      <img src="../assets/images/painter/submit.png" alt="" @click="submit" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stylesToChoose: false,
      selects: [],
    };
  },
  watch: {
    //监听路由变化
    isLogin: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getUserStyles();
        }
      },
    },
  },
  mounted() {
    this.getUserStyles();
  },
  methods: {
    // 获取用户风格
    getUserStyles() {
      this.$api.user.styles().then((res) => {
        if (!res.data) {
          this.getStyle();
        }
      });
    },
    // 获取风格
    getStyle() {
      this.$api.styles.list().then((res) => {
        this.stylesToChoose = true;
        res.data.forEach((item) => {
          item.checked = false;
        });
        this.selects = res.data;
      });
    },
    // 提交
    submit() {
      let checkedIds = [];
      this.selects.forEach((item) => {
        if (item.checked) {
          checkedIds.push(item.id);
        }
      });
      this.$emit("submit", checkedIds);
    },
  },
};
</script>

<style lang="less" scoped>
.styles-to-choose-content {
  padding: 0 10%;
  position: absolute;
  z-index: 999;
  width: 100%;
  box-sizing: border-box;
  .title {
    font-size: 1.5vw;
    font-family: 楷体;
    font-weight: 400;
    color: #ffffff;
    margin-top: 5%;
  }
  .selects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .button {
      margin-top: 5%;
      min-width: 15%;
      height: 2.5vw;
      background: url(/images/painter/style-button-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5vw;
      font-family: 楷体;
      font-weight: 400;
      color: #000000;
      cursor: pointer;
      padding: 0 1vw;
    }
    .buttonChecked {
      background: url("../assets/images/painter/style-button-bg-checked.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .submit {
    float: right;
    width: 20%;
    margin-top: 5%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
</style>
