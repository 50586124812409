<template>
  <div>
    <div class="StylesToChoose" v-show="stylesToChoose">
      <StylesToChoose
        ref="stylesToChoose"
        @submit="stylesToChooseSubmit"
      ></StylesToChoose>
    </div>
    <div class="content" v-show="!stylesToChoose">
      <div class="left">
        <div class="title">测试文案编辑</div>
        <el-form
          class="form"
          label-position="top"
          :model="form"
          :rules="rules"
          ref="ruleForm"
        >
          <el-form-item label="1.项目名称" prop="name" style="width: 30%">
            <el-input type="text" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item
            label="2.作品文案如下：（请画师认真阅读文案，再创意）"
            prop="content"
          >
            <el-input
              type="textarea"
              :autosize="{ minRows: 6 }"
              v-model="form.content"
            ></el-input>
          </el-form-item>
          <el-form-item label="3.作品风格参考" prop="images">
            <div class="uploader">
              <el-upload
                :action="uploadAction"
                :on-success="handleAvatarSuccess"
                :on-remove="handleAvatarRemove"
                list-type="picture-card"
                accept="image/png, image/jpeg"
                ref="upload"
              >
                <img
                  class="upload-img"
                  src="../../assets/images/College/upload-button-bg.png"
                  alt=""
                />
                <div class="upload-text">点击上传</div>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="4.作品尺寸">
            <div class="itemSize">
              <div class="size-title">高</div>
              <el-input
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.size.height"
                style="width: 20%"
              ></el-input>
              cm
              <div class="size-icon">
                <i class="el-icon-close"></i>
              </div>
              <div class="size-title">宽</div>
              <el-input
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="form.size.width"
                style="width: 20%"
              ></el-input>
              cm
            </div>
          </el-form-item>
          <el-form-item label="5.作品接收截止时间" prop="time">
            <el-date-picker
              v-model="form.time"
              type="datetime"
              placeholder="选择日期时间"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div class="buttons">
          <img
            src="../../assets/images/College/affirm.png"
            alt=""
            @click="stylesToChooseShow"
          />
        </div>
      </div>
      <CustomerService></CustomerService>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StylesToChoose from "../../components/StylesToChoose";
import CustomerService from "../../components/CustomerService.vue";
export default {
  components: { StylesToChoose, CustomerService },
  data() {
    return {
      stylesToChoose: false,
      uploadAction: "",
      form: {
        name: "",
        content: "",
        images: [],
        size: {
          width: "",
          height: "",
        },
        time: "",
        style_ids: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入作品文案", trigger: "change" },
        ],
        images: [
          { required: true, message: "请上传作品风格参考", trigger: "change" },
        ],
        time: [
          { required: true, message: "请选择作品尺寸", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.uploadAction = axios.defaults.baseURL + "api/common/upload";
    document.getElementsByClassName("content")[0].style.paddingRight = 0;
  },
  methods: {
    // 选择风格
    stylesToChooseSubmit(checkedIds) {
      this.form.style_ids = checkedIds;
      this.form.time = this.form.time / 1000;
      this.form.images = this.form.images.join(",");
      this.form.style_ids = this.form.style_ids.join(",");
      this.$api.project.add(this.form).then(() => {
        this.stylesToChoose = false;
        this.$refs.stylesToChoose.stylesToChoose = false;
        this.$refs.upload.clearFiles();
        this.form = {
          content: "",
          images: [],
          size: [],
          time: "",
          style_ids: "",
        };
      });
    },
    // 文件上传成功
    handleAvatarSuccess(res) {
      let { url } = res.data;
      this.form.images.push(url);
    },
    // 删除文件
    handleAvatarRemove(file) {
      let { url } = file.response.data;
      this.form.images = this.form.images.filter((item) => item != url);
    },
    // 显示风格选择
    stylesToChooseShow() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.stylesToChoose = true;
          this.$refs.stylesToChoose.stylesToChoose = true;
          this.$refs.stylesToChoose.getStyle();
        }
      });
    },
  },
  beforeDestroy() {
    document.getElementsByClassName("content")[0].style.paddingRight = "5vw";
  },
};
</script>

<style lang="less" scoped>
.StylesToChoose {
  position: relative;
}
.content {
  display: flex;
  .left {
    background: url("../../assets/images/enterprise/test/form-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 3%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .title {
      font-size: 1.2vw;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #aef5ff;
    }
    .form {
      height: 28vw;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .el-form-item {
        margin-top: 1.5vw;
      }
      .itemSize {
        display: flex;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        .size-title {
          margin-right: 1vw;
        }
        .size-icon {
          margin: 0 2%;
        }
      }
      .uploader {
        padding: 3% 5%;
        border: 1px solid #ffffff;
        border-radius: 4px;
        .upload-text {
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          position: absolute;
          top: 2vw;
          left: 0;
          width: 100%;
        }
      }
    }
    .buttons {
      width: 100%;
      float: right;
      display: flex;
      justify-content: flex-end;
      img {
        width: 10%;
        margin-left: 2%;
        cursor: pointer;
      }
    }
  }
}
</style>
